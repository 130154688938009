import { template as template_8b60d7b36dee48a2b8afee35392b1a33 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8b60d7b36dee48a2b8afee35392b1a33(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
