import { template as template_d188c64fc02a47edb604b2d3789c4d43 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import DButton from "discourse/components/d-button";
export default class PostMenuShowMoreButton extends Component {
    static shouldRender(args) {
        return args.state.collapsedButtons.length && args.state.collapsed;
    }
    static{
        template_d188c64fc02a47edb604b2d3789c4d43(`
    <DButton
      class="post-action-menu__show-more show-more-actions"
      ...attributes
      @action={{@buttonActions.showMoreActions}}
      @icon="ellipsis"
      @title="show_more"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
