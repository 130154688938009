import { template as template_09d1fd1ea58e4123a8531f62e1cfd440 } from "@ember/template-compiler";
const FKControlMenuContainer = template_09d1fd1ea58e4123a8531f62e1cfd440(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
